$main-color:#00497b;
$alert:rgb(172, 0, 0);
$info:rgb(1, 120, 142);
$disabled:rgb(203, 203, 203);

$code-0:rgba(203, 0, 0, 0.899);
$code-1:rgb(55, 0, 118);
$code-2:rgb(0, 91, 228);
$code-3:rgb(150, 4, 172);
$code-4:rgb(255, 47, 0);
$code-5:rgb(230, 127, 0);
$code-9:rgb(208, 0, 149);

.alert-color {
      color: $alert;
}

.info-color {
      color: $info;
}