.date-row {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & h4 {
            font-weight: bolder;
      }


}

.time-row {
      border-bottom: 1px solid rgb(48, 107, 108);
}

.add-btn {
      margin: 0;
      padding: 0;
      background-color: darkgrey;
      color: white;
      font-size: 2em;
      line-height: 50px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 5px grey;
      cursor: pointer;
      position: relative;
      z-index: 666;
      top: -10px;
      transition: all 400ms;

      &:hover {
            transform: translate(4px, 4px) scale(0.95, 0.95);
            box-shadow: 1px 1px 3px darkslategray;
      }

      & span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;

      }
}


.my-custom-scrollbar {
      position: relative;
      height: 40vh;
      overflow: auto;
}

.table-wrapper-scroll-y {
      width: 100%;
      display: block;
}

.tempo-progress-bar {
      .bar-container {
            position: relative;
            overflow: hidden;
            border-radius: 5px;

            &::after {
                  content: " ";
                  display: block;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  box-shadow: inset 2px 2px 4px rgba($main-color, 0.4);
            }

      }

}

.overtime-alert {
      color: $alert;
}

.undertime-alert {
      color: $info;
}

.code-opt {

      &.code-0 {
            color: $code-0;
      }

      &.code-1 {
            color: $code-1;
      }

      &.code-2 {
            color: $code-2;
      }

      &.code-3 {
            color: $code-3;
      }

      &.code-4 {
            color: $code-4;
      }

      &.code-5 {
            color: $code-5;
      }

      &.code-9 {
            color: $code-9;
      }
}

.admin-entry {
      & .entry-row {
            border-bottom: 1px solid $main-color;
      }
}