@import "./styles/_colors";
@import "./styles/_typography";
@import "./styles/_programs";
@import "./styles/_employees";
@import "./styles/_weeks";
@import "./styles/_nav";

.App {
      text-align: left;
      background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4)), url("/img/bg_header2.png");
      background-size: 10%;
      background-repeat: repeat;
      min-height: 100vh;
}

.App-logo {
      animation: App-logo-spin infinite 20s linear;
      height: 80px;
}

.App-header {
      background-color: #222;
      height: 150px;
      padding: 20px;
      color: white;
}

.App-title {
      font-size: 1.5em;
}

.App-intro {
      font-size: large;
}

@keyframes App-logo-spin {
      from {
            transform: rotate(0deg);
      }

      to {
            transform: rotate(360deg);
      }
}