@font-face {
      font-family: 'Rakesly';
      src: url('/font/rakesly_bk.ttf') format('truetype'),
}

$main-font:'Rakesly',
sans-serif;
$main-font2:'Raleway',
sans-serif;
$accent-font:'Courgette',
cursive;
$reading-font:'Montserrat',
sans-serif;

h1,
h2,
h3 {
      font-family: $main-font;
}

h4,
h5,
h6 {
      font-family: $main-font2;
}

p,
small,
span,
a,
li {
      font-family: $main-font2;
}