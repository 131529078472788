.employees-list {
      & .alert-text {
            color: rgb(201, 0, 0);
            font-weight: bolder;
      }

      .user-name {
            color: $main-color;
            font-size: 1.2em;
      }

      .user-role {
            font-size: 0.75em;
            color: #828282;
            font-style: italic;
      }
}

.btn .disabled {
      color: #828282;
      cursor: not-allowed;
}

.employee-row {
      border-bottom: 1px solid $main-color;
      padding: 10px 0;

      &:hover {
            background-color: rgba(7, 45, 74, 0.098);
      }
}