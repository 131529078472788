.entete {
      color: $main-color;
      font-weight: 100;
      font-family: $reading-font;
      letter-spacing: 0.1em;
      font-stretch: expanded;
      font-size: 0.85em;
}

.navbar {
      color: $main-color;
      background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)), url('/img/bg_header2.png');
      background-size: 10%;

      & * {
            color: $main-color;
            font-family: $main-font2;
      }

      box-shadow: 2px 2px 5px rgba($main-color, 0.269);
}

.btn.btn-disabled {
      background-color: $disabled;
      border: 1px solid white;
      font-style: italic;
      pointer-events: none;
}